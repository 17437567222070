<template>
    <div class="payout-item"> 
      <div @click="toggleOpen" class="payout-item-head"> 
        <div class="title">
            <div class="icon">
              <fa-icon size="2x" :icon="['fa', 'money-check']" />
            </div>
            <div class="text">
              <h3>{{ item.user.garage.name }}</h3>
              <span>{{ item.user.firstname + " " + item.user.lastname }}</span>
            </div>
        </div>
        <div class="summary">
          <div class="amount-summary">
            <div class="final">
              <span class="amount">{{ totalAmountNet }} EUR</span>
              <span class="notice">Offen</span>
            </div>
            <div class="total">
              <span class="amount">{{ totalAmount }} EUR</span>
              <span class="notice">Umsatz</span>
            </div>
          </div>
          <div class="action">
            <div class="more-button">
              <fa-icon :icon="['fa', open ? 'chevron-up' : 'chevron-down']" />
            </div>
          </div>
        </div>
      </div>
      <div v-if="open" class="payout-item-body">
        <div class="offers">
          <div v-for="offer in item.offers" :key="offer._id" class="offer">
            <div class="title">
              <InvoiceButton v-for="invoice in offer._invoices" :item="invoice" :key="invoice._id" />
              <div>
                <h4>
                  Reperatur
                  <button :disabled="loadingReRenderInvoices" @click="rerenderInvoices(offer)" class="reload-button">
                    <fa-icon :icon="['fa', 'sync']" />
                  </button>
                </h4>
                <span>#{{offer.damageId}}</span>
              </div>
            </div>
            <div class="summary">
              <div class="amount-summary">
                <div class="final">
                  <span class="amount">{{ offer.price + offer._fee }} EUR</span>
                  <span class="notice">Umsatz</span>
                </div>
                <div class="total">
                  <span class="amount">{{ offer._fee }} EUR</span>
                  <span class="notice">Provision</span>
                </div>
                <div class="open">
                  <span class="amount">{{ offer.price }} EUR</span>
                  <span class="notice">Offen</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="buttons">
          <el-button v-if="!isHistory" @click="markPayed" :loading="loading">{{ totalAmountNet }} EUR wurde ausgezahlt</el-button>
        </div>
      </div>
    </div>
</template>

<script>
import apiService from "../services/api";
import InvoiceButton from '@/components/InvoiceButton'

export default {
  name: 'PayoutItem',
  props: ["item", "isHistory"],
  data() {
    return {
      open: false,
      loading: false,
      loadingReRenderInvoices: false,
    }
  },
  computed: {
    totalAmount() {
      return this.item.offers.reduce((acc, offer) => {
        return acc + offer.price + offer._fee;
      }, 0);
    },
    totalAmountNet() {
      return this.item.offers.reduce((acc, offer) => {
        return acc + offer.price;
      }, 0);
    },
    totalFee() {
      return this.item.offers.reduce((acc, offer) => {
        return acc + offer._fee;
      }, 0);
    }
  },
  methods: {
    toggleOpen() {
      this.open = !this.open;
    },
    async markPayed() {
      this.loading = true;
      const promises = this.item.offers.map(offer => apiService.payoutPayed(offer._id));
      try {
        await Promise.all(promises);
        this.$emit("payed");
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
    },
    async rerenderInvoices(offer) {
      this.loadingReRenderInvoices = true;
      try {
        await apiService.rerenderInvoices(offer.damageId)
        this.$message({
          type: 'success',
          message: 'Generating invoices is in queue now. Please wait a few minutes and refresh the page.'
        });
      } catch (e) {
        this.$message({
          type: 'error',
          message: 'Unknown error occurred. Please try again later.'
        });
      }
      this.loadingReRenderInvoices = false;
    }
  },
  components: {
    InvoiceButton
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";
.payout-item {
  border: 1px solid rgb(221, 221, 221);
  border-radius: 19px;

  .payout-item-head {
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      margin: 10px;
      padding: 15px;
      cursor: pointer;

      .title {
        position: relative;
        display: flex;
        align-items: center;

        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;
          width: 80px;
          height: 80px;
          background: rgba(0, 0, 0, 0.12);
          border-radius: 50%;
        }

        .text {
          margin-left: 20px;
          h3 {
            margin: 0;
            font-size: 1.2rem;
            font-weight: 500;
          }
          span {
            font-size: 0.9rem;
            color: rgba(0, 0, 0, 0.35);
          }
        }
      }

      .summary {
        display: flex;

        .action {
          width: 40px;
          display: flex;
          align-items: center;

          .more-button {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            width: 32px;
            height: 32px;
            color: rgba(0, 0, 0, 0.35);
            border-radius: 50%;
            margin-left: 20px;
          }
        }

        .amount-summary {

          display: flex;
          flex-direction: column;
          justify-content: center;

          > div {
            width: 160px;
            display: flex;
            justify-content: space-between;

            .amount {
              width: 100px;
              text-align: right;
            }

            .notice {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              width: 60px;
              text-align: right;
              font-size: 0.8rem;
              color: rgba(0, 0, 0, 0.35);
            }

            &.final {
              .amount {
                font-weight: bold;
                font-size: 16px;
              }
            }

            &.total {
              .amount {
                color: rgba(0, 0, 0, 0.32);
                font-size: 16px;
              }
            }
          }
        }
      }
  }

  .payout-item-body {
    margin-top: 10px;
    padding: 15px 0;
    border-top: 1px solid rgb(221, 221, 221);

    .buttons {
      text-align: center;
    }

    .offers {

      .offer {
        margin-bottom: 12px;
        display: flex;
        justify-content: space-between;
        padding: 7px 24px;

        &:hover {
          background: rgba(0, 0, 0, 0.05);
        }

        .title {
          display: flex;
          justify-content: start;
          align-items: center;

          .reload-button {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            width: 24px;
            height: 24px;
            color: rgb(255, 103, 103);
            border: none;
            border-radius: 50%;
            margin-left: 4px;
            font-size: 10px;
            background: rgb(255, 235, 235);
            cursor: pointer;

            &:active {
              background: rgb(255, 169, 169);
              color: rgb(255, 235, 235);
            }

            &:disabled {
              cursor: not-allowed;
              background: rgba(255, 235, 235, 0.2);
              color: rgba(0, 0, 0, 0.2);
            }
          }

          h4 {
            margin: 0;
            font-size: 1.2rem;
            font-weight: 500;
            display: flex;
            justify-content: start;
            align-items: center;
          }
          
          span {
            font-size: 0.8rem;
            color: rgba(0, 0, 0, 0.35);
          }
        }

        .amount-summary {

          display: flex;
          flex-direction: column;
          justify-content: center;

          > div {
            width: 160px;
            display: flex;
            justify-content: space-between;

            .amount {
              width: 100px;
              text-align: right;
            }

            .notice {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              width: 60px;
              text-align: right;
              font-size: 10px;
              color: rgba(0, 0, 0, 0.35);
            }

            &.final {
              .amount {
                color: rgba(0, 0, 0, 0.32);
                font-size: 12px;
              }
            }

            &.total {
              .amount {
                color: rgba(0, 0, 0, 0.32);
                font-size: 12px;
              }
            }

            &.open {
              border-top: dashed 1px rgba(0, 0, 0, 0.32);
              padding-top: 2px;
              margin-top: 2px;

              .amount {
                color: rgba(0, 0, 0, 0.62);
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
