<template>
  <div class="page">
    <el-tabs v-model="activeTab">
      <el-tab-pane label="Offen" name="open">
        <PayoutItem v-for="(item, userId) in open" :item="item" :key="userId" @payed="payed" />
      </el-tab-pane>
      <el-tab-pane label="Verlauf" name="history">
        <PayoutItem v-for="(item, userId) in history" :item="item" :key="userId" :isHistory="true" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { FETCH_PAYOUTS } from '../vuex/modules/payouts/actions';
import PayoutItem from '@/components/PayoutItem'

export default {
  name: 'Payout',
  data() {
    return {
      activeTab: 'open',
    }
  },
  computed: {
    ...mapState({
      open: state => state.payouts.open,
      history: state => state.payouts.history,
      loading: state => state.payouts.loading,
      env: state => state.settings.env
    }),
    isProd() {
      return this.env === "production";
    },
  },
  created() {
    this.updatePayouts();
  },
  methods: {
    updatePayouts() {
      this.$store.dispatch(FETCH_PAYOUTS, {
        search: this.search
      })
    },
    payed() {
      this.updatePayouts();
    }
  },
  components: {
    PayoutItem,
  }
}
</script>

<style lang="scss" scoped>
  .search {
    margin: 20px;
  }
</style>
