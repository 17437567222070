<template>
    <div v-if="item.status == 'done'" class="pdf" :class="{ loading: requestingPdf }" @click="download">
      <span class="badge" :class="{ g: item.invoiceIdPrefix == 'G' }">{{item.invoiceIdPrefix}}</span>
      <fa-icon size="2x" :icon="['fa', 'file-pdf']" />
    </div>
    <div v-else-if="item.status == 'in_queue'" class="pdf-queue">
      <fa-icon :icon="['fa', 'clock']" />
    </div>
    <div v-else-if="item.status == 'failed'" class="pdf-failed">
      <fa-icon :icon="['fa', 'exclamation-circle']" />
    </div>
</template>

<script>
import { mapState } from "vuex";
import filesService from "@/services/files";

export default {
  name: 'InvoiceButton',
  props: ["item"],
  computed: {
    ...mapState({
      env: state => state.settings.env
    }),
    isProd() {
      return this.env === "production";
    },
  },
  data() {
    return {
      pdfBlob: null,
      requestingPdf: false
    }
  },
  methods: {
    async download() {
      if (this.requestingPdf) return;
      this.requestingPdf = true;
      if (!this.pdfBlob) {
        this.pdfBlob = URL.createObjectURL(
          await filesService.getImage(this.item.pdf.key, this.isProd),
        );
      }
      this.requestingPdf = false;
      let tab = window.open();
      tab.location.href = this.pdfBlob;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";
.pdf {
  position: relative;
  width: 40px;
  flex-grow: 0;
  margin: 0;
  cursor: pointer;

  :hover {
    color: rgb(105, 123, 141);
  }

  &.loading {
    opacity: 0.5;
    cursor: default;

    :hover {
      color: black;
    }
  }

  .badge {
    position: absolute;
    top: -5px;
    left: -5px;
    font-size: 8px;
    padding: 1px 4px;
    border-radius: 14px;
    background: rgba(255, 61, 139, 1);
    color: white;
    pointer-events: none;
    font-weight: bold;

    &.g {
      background: rgb(61, 194, 255);
    }
  }
}

.pdf-queue {
  width: 30px;
  height: 40px;
  margin-right: 7px;
  border-radius: 4px;
  border: 1px dashed rgb(179, 179, 179);
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(179, 179, 179);
}

.pdf-failed {
  width: 30px;
  height: 40px;
  margin-right: 7px;
  border-radius: 4px;
  border: 1px dashed rgb(255, 110, 110);
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(255, 110, 110);
}
</style>
